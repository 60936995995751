import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { Close as CloseIcon, HelpOutlined } from '@mui/icons-material';
import { fetchKnowledgebasePostsBySlug } from "../store/contentful/contentful";
import ContentfulPostRenderer from "./Knowledge-Base-Post-Render";

const HelpDrawer = ({ slug }) => {
  const initialDrawerWidth = 400;
  const [open, setOpen] = useState(false);
  const [post, setPost] = useState(null);
  const [drawerWidth, setDrawerWidth] = useState(initialDrawerWidth);
  const [isResizing, setIsResizing] = useState(false);

  const getPost = async () => {
    const postData = await fetchKnowledgebasePostsBySlug("default", slug);
    setPost(postData);
  };

  useEffect(() => {
    if (open && slug) {
      getPost();
    }
  }, [slug, open]);

  const toggleDrawer = () => {
    if (!open) {
      setDrawerWidth(initialDrawerWidth);
    }
    setOpen(!open);
  };

  // Handle resizing
  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsResizing(true);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const newWidth = window.innerWidth - e.clientX;
    if (newWidth >= 200 && newWidth <= window.innerWidth * 0.6) { // Limit resize range
      setDrawerWidth(newWidth);
    }
  };

  const handleMouseUp = () => {
    setIsResizing(false);
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  return (
    <>
      <IconButton
        onClick={toggleDrawer}
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          bgcolor: "primary.main",
          color: "white",
          boxShadow: 2,
          transition: "transform 0.2s ease",
          "&:hover": {
            transform: "scale(1.2)",
            bgcolor: "primary.main",
          },
          zIndex: 1400,
        }}
      >
        {open ? <CloseIcon /> : <HelpOutlined />}
      </IconButton>


      <Box
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          height: "100vh",
          width: open ? `${drawerWidth}px` : 0,
          backgroundColor: "background.paper",
          boxShadow: 3,
          overflowY: "auto",
          zIndex: 1300,
          display: "flex",
          flexDirection: "column",
          transition: open && !isResizing ? "width 0.4s ease" : "none",
        }}
      >
        {open && (
          <Box
            onMouseDown={handleMouseDown}
            sx={{
              width: "5px",
              cursor: "ew-resize",
              position: "absolute",
              left: 0,
              top: 0,
              bottom: 0,
              zIndex: 1400,
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            }}
          />
        )}

        {/* Close Button inside the Drawer */}
        {open && (
          <IconButton
            onClick={toggleDrawer}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'grey.700',
            }}
          >
            <CloseIcon />
          </IconButton>
        )}

        <Box sx={{ p: 3, pt: 6 }}>
          {post ? (
            <>
              <Typography variant="h2" sx={{ fontSize: "1.8rem", flexGrow: 1, mb: 2 }}>
                {post.fields.title}
              </Typography>
              <ContentfulPostRenderer post={post} />
            </>
          ) : (
            <Typography>Loading...</Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default HelpDrawer;
