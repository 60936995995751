import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { fetchKnowledgebasePostsByType } from "../store/contentful/contentful";
import SubCard from "../ui-component/cards/SubCard";

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// ==============================|| Knowledge Base "Home Page"||============================== //
const KnowledgeBase = () => {
  const [posts, setPosts] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState("");
  const [search, setSearch] = useState("");

  const getPosts = async () => {
    const postsData = await fetchKnowledgebasePostsByType("default");
    setPosts(postsData);
    // Extract tags from metadata and capitalize them
    const allTags = postsData.flatMap((post) =>
      (post.metadata.tags || []).map((tag) => capitalizeFirstLetter(tag.sys.id))
    );
    const uniqueTags = [...new Set(allTags)];
    setTags(uniqueTags);
  };

  useEffect(() => {
    getPosts()
  }, []);

  // Filter posts based on search and selected tag
  const filteredPosts = posts.filter(
    (post) =>
      (post.fields.title?.toLowerCase().includes(search.toLowerCase()) ||
        post.fields.description?.toLowerCase().includes(search.toLowerCase())) &&
      (selectedTag ? post.metadata.tags?.some((tag) => tag.sys.id === selectedTag.toLowerCase()) : true)
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography
        variant="h1"
        gutterBottom
        sx={{
          fontWeight: "bold",
          fontSize: "2.8rem",
          textAlign: "center",
          marginBottom: 3,
        }}
      >
        How can we help you?
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <TextField
          variant="outlined"
          fullWidth
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          label="Search"
          sx={{
            mb: 3,
            maxWidth: {
              xs: "90%",
              sm: "70%",
            },
          }}
          InputLabelProps={{
            sx: {
              fontSize: "1.2rem",
              lineHeight: "1.2rem",
              transform: "translate(25px, 18px)",
              "&.MuiInputLabel-shrink": {
                fontSize: "1rem",
                transform: "translate(12px, -8.5px)",
              },
            }
          }}
          InputProps={{
            sx: {
              height: 60,
              fontSize: "1.2rem",
              lineHeight: "1.2rem",
            },
          }}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
        {tags.map((tag) => (
          <Chip
            key={tag}
            label={tag}
            clickable
            onClick={() => setSelectedTag(tag)}
            sx={{
              margin: "0 5px",
              fontSize: "1rem",
              padding: "8px",
              backgroundColor: selectedTag === tag ? "primary.main" : "default",
              color: selectedTag === tag ? "white" : "black",
              transition: "background-color 0.3s ease, transform 0.2s ease, color 0.2s ease",
              "&:hover": {
                backgroundColor: "primary.dark",
                color: "primary.main",
                transform: "scale(1.05)",
              },
            }}
          />
        ))}
        {selectedTag && (
          <Button
            onClick={() => setSelectedTag("")}
            sx={{
              marginLeft: 2,
              transition: "background-color 0.3s ease, transform 0.2s ease, color 0.2s ease",
              "&:hover": {
                backgroundColor: "primary.light",
                color: "primary.main",
                transform: "scale(1.05)",
              },
            }}
          >
            Clear Tag Filter
          </Button>
        )}
      </Box>

      {filteredPosts.length > 0 ? (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {filteredPosts.map((post) => (
            <Grid item xs={12} sm={6} md={4} key={post.sys.id}>
              <Link
                to={`/knowledge-base/${post.fields.slug}`}
                style={{ textDecoration: "none" }}
              >
                <SubCard
                  title={post.fields.title}
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    transition: "transform 0.4s ease, box-shadow 0.4s ease",
                    "&:hover": {
                      transform: "translateY(-8px)",
                      boxShadow: "0 10px 20px rgba(0, 0, 0, 0.24)",
                    },
                  }}
                >
                  <Typography variant="body2">
                    {post.fields.description}
                  </Typography>
                </SubCard>
              </Link>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography
          variant="h6"
          sx={{ textAlign: "center", mt: 4, fontStyle: "italic" }}
        >
          Hmm... no matches here. Try adjusting your search, or maybe explore some other tags!
        </Typography>
      )}
    </Container>
  );
};

export default KnowledgeBase;
