import PropTypes from 'prop-types';
import {forwardRef} from 'react';

// material-ui
import {useTheme} from '@mui/material/styles';
import {Card, CardContent, CardHeader, Divider, Typography} from '@mui/material';

// ==============================|| CUSTOM SUB CARD ||============================== //

const SubCard = forwardRef(({ children, content, contentClass, darkTitle, secondary, sx = {}, contentSX = {}, title, ...others }, ref) => {
  const theme = useTheme();

  return (
    <Card
      ref={ref}
      sx={{
        border: '1px solid',
        borderColor: theme.palette.primary.light,
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Elevation shadow

          ':hover': {
          boxShadow: '2px 2px 24px 0 rgb(32 40 45 / 8%)'
        },
        ...sx
      }}
      {...others}
    >
      {!darkTitle && title && <CardHeader sx={{ p: 2 }} title={<Typography variant="h3" sx={{fontWeight: 700}}>{title}</Typography>} action={secondary} />}
      {darkTitle && title && <CardHeader sx={{ p: 2 }} title={<Typography variant="h3" sx={{fontWeight: 700}}>{title}</Typography>} action={secondary} />}

      {title && (
        <Divider
          sx={{
            opacity: 1,
            borderColor: theme.palette.primary.light
          }}
        />
      )}

      {content && (
        <CardContent sx={{ p: 2, ...contentSX }} className={contentClass || ''}>
          {children}
        </CardContent>
      )}
      {!content && children}
    </Card>
  );
});

SubCard.propTypes = {
  children: PropTypes.node,
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  darkTitle: PropTypes.bool,
  secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
  sx: PropTypes.object,
  contentSX: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

SubCard.defaultProps = {
  content: true
};

export default SubCard;
