// GetFxQuote.js
import React, { useContext, useEffect, useRef, useState } from "react";

// materialUI imports
import { Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project imports
import APIRequestButton from "../ui-component/extended/APIRequestButton";
import CustomDropdown from "../ui-component/form-fields/CustomDropdown";
import { useAuthHeaders } from "../auth/AuthHeaders";
import CircularSpinner from "../ui-component/CircularSpinner";
import { FadeInBox } from "../ui-component/extended/FadeInBox";
import FxQuoteResponseDisplay from "./response/FxQuoteResponseDisplay";
import { useBeneficiaries } from "../store/context/EntityContext";
import ConfigContext from "../store/context/ConfigContext";
import { displayBeneficiary } from "store/displayBeneficiary";
import LedgerDropdown from "../ui-component/ledger/LedgerDropdown"; // Import LedgerDropdown
// 3rd Party imports
import axios from "axios";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { RolesContext } from "../store/context/RolesContext";
import { getCurrencies } from "../store/hooks/useConfiguredCurrencies";

// ============================|| GET FX QUOTE ||============================ //
const GetFxQuote = ({
  verticalResponse = false,
  activeLedgers,
  ledgerLoading,
}) => {
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isResponseReceived, setIsResponseReceived] = useState(false);
  const [ledgerId, setLedgerId] = useState("");
  const responseRef = useRef(null);
  const { roles } = useContext(RolesContext);
  const config = useContext(ConfigContext);
  const theme = useTheme();
  const authHeaders = useAuthHeaders();
  const { entityData: beneficiaries, triggerReload } = useBeneficiaries();
  const clientId = sessionStorage.getItem("clientId");
  const rateDisclaimer = sessionStorage.getItem("rateDisclaimer");

  useEffect(() => {
    if (Array.isArray(activeLedgers) && activeLedgers.length > 0) {
      const defaultLedger =
        activeLedgers.find((ledger) => ledger.currency === "USD") ||
        activeLedgers[0];
      setLedgerId(defaultLedger.ledgerId);
    }
  }, [activeLedgers]);

  const beneficiaryOptions = Array.isArray(beneficiaries)
    ? beneficiaries
        .filter((entity) => entity.state === "ready")
        .map((entity) => ({
          value: entity.reference,
          label: displayBeneficiary(entity),
        }))
    : [];
  beneficiaryOptions.sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (Array.isArray(beneficiaries) && beneficiaries.length === 0) {
      triggerReload();
    }
  }, [beneficiaries]);

  useEffect(() => {
    if (response && responseRef.current) {
      responseRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      setIsResponseReceived(true);
    }
  }, [response]);

  const clearResponseAndError = () => {
    setResponse(null);
    setIsResponseReceived(false);
  };

  // --------------Quote Form Submission ---------------//

  const initialFormValues = {
    entityId: "",
    currency: "CAD",
    ledgerId: ledgerId,
  };

  const handleGetQuoteSubmit = async (values) => {
    const requestData = {
      clientId: clientId,
      request: {
        entityId: values.entityId,
        currency: values.currency,
        sellAmount: 1,
        ledgerId: values.ledgerId,
      },
    };
    setIsLoading(true);
    clearResponseAndError();
    try {
      const response = await axios.post(
        `${config.API_URL}/figaro/v1/quote`,
        requestData,
        {
          headers: authHeaders,
        },
      );
      setResponse({ data: response.data, request: requestData });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        window.location.href = "/token-expiry";
      }
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const currencyOptions = getCurrencies();

  // ----------------------------------------------//

  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          lg={isResponseReceived && !verticalResponse ? 4 : 12}
        >
          <Formik
            initialValues={initialFormValues}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              entityId: Yup.string()
                .trim()
                .notOneOf([""], "Entity cannot be empty")
                .required("Beneficiary is required"),
              currency: Yup.string().required("Currency is required"),
            })}
            onSubmit={handleGetQuoteSubmit}
          >
            {({
              values,
              handleChange,
              handleBlur,
              isSubmitting,
              touched,
              errors,
            }) => (
              <Form>
                <CustomDropdown
                  name="currency"
                  label="Currency"
                  value={roles.includes("quote") ? values.currency : "USD"}
                  handleChange={(e) => {
                    handleChange(e);
                    clearResponseAndError();
                  }}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  options={currencyOptions}
                  maxHeight={"50vh"}
                  textSize={"1rem"}
                  disabled={!roles.includes("quote")}
                  isCurrencyField={true}
                />

                <CustomDropdown
                  name="entityId"
                  label="Beneficiary"
                  value={values.entityId}
                  handleChange={(e) => {
                    handleChange(e);
                    clearResponseAndError();
                  }}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  maxHeight={"40vh"}
                  options={beneficiaryOptions}
                />
                <Box sx={{ mt: 1 }} />
                {activeLedgers && activeLedgers.length > 1 && (
                  <LedgerDropdown
                    name="ledgerId"
                    label="Source Account"
                    value={values.ledgerId}
                    handleChange={(e) => {
                      handleChange(e);
                      clearResponseAndError();
                    }}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    maxHeight={500}
                    textSize={"1rem"}
                  />
                )}
                {isLoading || ledgerLoading ? (
                  <CircularSpinner />
                ) : (
                  <APIRequestButton
                    isSubmitting={isSubmitting}
                    showRefreshButton={false}
                  />
                )}
              </Form>
            )}
          </Formik>
          {/*)}*/}
        </Grid>
        <Grid
          item
          xs={12}
          lg={isResponseReceived && !verticalResponse ? 8 : 12}
        >
          {response && (
            <FadeInBox
              ref={responseRef}
              sx={{
                height: "100%",
                [theme.breakpoints.up("lg")]: {
                  ml: verticalResponse ? 0 : 5,
                },
              }}
            >
              <FxQuoteResponseDisplay
                response={response.data}
                request={response.request}
              />
              {rateDisclaimer && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    fontStyle: "italic",
                    fontWeight: "bold",
                    fontSize: "0.875rem",
                  }}
                  my={1}
                  ml={1}
                >
                  {rateDisclaimer}
                </Typography>
              )}
            </FadeInBox>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
export default GetFxQuote;
