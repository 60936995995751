import React from "react";
import getFormattedCurrencyAmount, {
  getFormattedCurrencyAmountWithName,
} from "store/getFormattedCurrencyAmount";
import DataGridStatusChip from "./DataGridStatusChip";
import {
  formatDateToMonthDayYearWithTime,
  dateComparator,
} from "common/getDate";
import { capitalize } from "common/stringUtils";
const formatClientRate = (rate) => {
  if (rate) {
    return parseFloat(rate).toString();
  }
  return "";
};

export const transactionHistoryColumns = [
  {
    field: "type",
    headerName: "Type",
    flex: 0.5,
    hide: true,
    valueGetter: (value) => capitalize(value),
  },
  { field: "reference", headerName: "Reference", flex: 1 },
  {
    field: "sendAmountWithCurrency",
    headerName: "Amount",
    flex: 0.75,
    sortable: true,
    valueGetter: (value, row) => {
      if (row) {
        return `${getFormattedCurrencyAmountWithName(
          row.amount,
          row.currency
        )}`;
      }
      return "";
    },
    sortComparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
      const numericAmountA = parseFloat(valueA.replace(/[^\d.-]/g, ""));
      const numericAmountB = parseFloat(valueB.replace(/[^\d.-]/g, ""));

      if (numericAmountA < numericAmountB) {
        return isInverted ? 1 : -1;
      } else if (numericAmountA > numericAmountB) {
        return isInverted ? -1 : 1;
      } else {
        return 0;
      }
    },
  },
  { field: "account", headerName: "Source Account", flex: 0.75 },
  {
    field: "createdAt",
    headerName: "Created At",
    flex: 0.75,
    sortable: true,
    sortComparator: dateComparator,
    valueGetter: (value, row) => {
      if (!row || !row.createdAt) return "";
      const dateObject = new Date(row.createdAt);
      const formattedDate = formatDateToMonthDayYearWithTime(dateObject);
      return formattedDate || "";
    },
  },
  {
    field: "state",
    headerName: "Status",
    flex: 0.25,
    headerAlign: "right",
    align: "right",
    sortable: true,
    renderCell: (params) => <DataGridStatusChip state={params.value} />,
  },
];

export const bankTransactionHistoryColumns = [
  {
    field: "type",
    headerName: "Type",
    flex: 1,
    hide: true,
    disableExport: true,
  },
  { field: "client", headerName: "Sending Client", flex: 1 },
  { field: "sendCurrency", headerName: "Send Currency", flex: 0.25 },
  {
    field: "sendAmountWithCurrency",
    headerName: "Sends",
    flex: 1,
    sortable: true,
    valueGetter: (value, row) => {
      if (row) {
        return `${getFormattedCurrencyAmountWithName(
          row.sendAmount,
          row.sendCurrency
        )}`;
      }
      return null;
    },
    sortComparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
      const numericAmountA = parseFloat(valueA.replace(/[^\d.-]/g, ""));
      const numericAmountB = parseFloat(valueB.replace(/[^\d.-]/g, ""));

      if (numericAmountA < numericAmountB) {
        return isInverted ? 1 : -1;
      } else if (numericAmountA > numericAmountB) {
        return isInverted ? -1 : 1;
      } else {
        return 0;
      }
    },
  },
  { field: "entity", headerName: "Beneficiary", flex: 1 },
  { field: "receiveCurrency", headerName: "Receive Currency", flex: 0.25 },
  {
    field: "receiveAmountWithCurrency",
    headerName: "Receives",
    flex: 1,
    sortable: true,
    valueGetter: (value, row) => {
      if (row) {
        return `${getFormattedCurrencyAmountWithName(
          row.receiveAmount,
          row.receiveCurrency
        )}`;
      }
      return "";
    },
    sortComparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
      const numericAmountA = parseFloat(valueA.replace(/[^\d.-]/g, ""));
      const numericAmountB = parseFloat(valueB.replace(/[^\d.-]/g, ""));

      if (numericAmountA < numericAmountB) {
        return isInverted ? 1 : -1;
      } else if (numericAmountA > numericAmountB) {
        return isInverted ? -1 : 1;
      } else {
        return 0;
      }
    },
  },
  {
    field: "clientRate",
    headerName: "Client Rate",
    valueGetter: (value, row) => {
      if (row) {
        return `${formatClientRate(row.clientRate)}`;
      }
      return "";
    },
  },
  {
    field: "actualSellAmount",
    headerName: "Actual Sell Amount",
    valueGetter: (value, row) => {
      if (row) {
        return `${getFormattedCurrencyAmount(
          row.actualSellAmount,
          row.sellCurrency
        )}`;
      }
      return "";
    },
  },
  { field: "reference", headerName: "Reference", flex: 1.5 },
  {
    field: "createdAt",
    headerName: "Created At",
    flex: 1,
    sortable: true,
    sortComparator: dateComparator,
    valueGetter: (value, row) => {
      if (!row || !row.createdAt) return "";
      const dateObject = new Date(row.createdAt);
      const formattedDate = formatDateToMonthDayYearWithTime(dateObject);
      return formattedDate || "";
    },
  },
  {
    field: "state",
    headerName: "Status",
    flex: 0.5,
    headerAlign: "right",
    align: "right",
    sortable: true,
    renderCell: (params) => <DataGridStatusChip state={params.value} />,
  },
];
