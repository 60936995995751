import React, { useContext } from "react";
import { Box, Grid } from "@mui/material";
import CreateEntityForm from "../requests/entities/CreateEntity";
import { RolesContext } from "../store/context/RolesContext";
import { useNavigate } from "react-router-dom";
import HelpDrawer from "../ui-component/HelpDrawer";

const CreateBeneficiary = () => {
  const { roles } = useContext(RolesContext);
  const navigate = useNavigate();

  if (!roles.includes("beneficiary_create")) {
    navigate("/");
  }
  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Box sx={{ mt: 5, width: "95%" }}>
          <CreateEntityForm />
        </Box>
      </Grid>
      <HelpDrawer slug="create-beneficiary" />
    </>
  );
};

export default CreateBeneficiary;
