import { useEffect, useState } from 'react';
import { util } from '@wire-fx/core-ui';

// Utility function to safely parse JSON
const safeParseJSON = (data) => {
    try {
        return data ? JSON.parse(data) : [];
    } catch {
        return [];
    }
};

const blockedCountries = safeParseJSON(sessionStorage.getItem("blockedCountries"));
const countries =  util.country.getAllCountries(JSON.stringify(blockedCountries));

export const useCountryRegions = (handleChange, defaultCountry = null) => {
    const [regions, setRegions] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);

    const handleCountryChange = (event) => {
        const selectedValue = event.target.value;
        const selectedCountryOption = countries.find(country => country.value === selectedValue);
        setSelectedCountry(selectedCountryOption);
        handleChange(event);
    };

    // Check if defaultCountry is provided.
    useEffect(() => {
        if (defaultCountry) {
            const defaultCountryOption = countries.find(country => country.value === defaultCountry);
            if (defaultCountryOption) {
                setSelectedCountry(defaultCountryOption);
            }
        }
    }, [defaultCountry, countries]);

    // Logic to get the regions or as Canadians say, provinces, ehhhhhh.
    useEffect(() => {
        if (selectedCountry && selectedCountry.regions) {
            const newRegions = selectedCountry.regions.map(regionArray => ({
                value: regionArray[1],
                label: regionArray[0],
            }));
            setRegions(newRegions);
        } else {
            setRegions([]);
        }
    }, [selectedCountry]);

    return { countries, regions, handleCountryChange, selectedCountry };
};
