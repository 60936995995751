import { createClient } from 'contentful';

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

const getContentTypeByKey = (key) => {
  switch (key.toLowerCase()) {
    case 'ffb':
      return 'ffb';
      case 'default':
      return 'general';
    default:
      return 'post';
  }
};

// Function to fetch entries based on the key that determines the content type
export const fetchKnowledgebasePostsByType = async (key) => {
  const contentType = getContentTypeByKey(key);
  const response = await client.getEntries({
    content_type: contentType,
  });
  return response.items;
};

// Function to fetch a specific entry by slug and content type
export const fetchKnowledgebasePostsBySlug = async (key, slug) => {
  const contentType = getContentTypeByKey(key);
  const response = await client.getEntries({
    content_type: contentType,
    'fields.slug': slug,
    limit: 1,
  });
  return response.items[0];
};
