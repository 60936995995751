import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Box, Button, Container, Divider, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import ContentfulPostRenderer from "../ui-component/Knowledge-Base-Post-Render";
import { fetchKnowledgebasePostsBySlug } from "../store/contentful/contentful";

const KnowledgeBasePost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);

  // todo: Need to use config variable to set the type to return here IE replace default.
  const getPost = async () => {
    const postData = await fetchKnowledgebasePostsBySlug("default", slug);
    setPost(postData);
  };

  useEffect(() => {
    getPost();
  }, [slug]);

  if (!post) return <Typography>Loading...</Typography>;

  return (
    <Container>
      <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
        <Button
          component={Link}
          to="/knowledge-base"
          variant="text"
          startIcon={<ArrowBack />}
          sx={{
            minWidth: 'auto',
            p: 0,
            mr: 2,
          }}
        />
        <Typography variant="h1" sx={{ fontSize: "3rem", flexGrow: 1, my: 0 }}>
          {post.fields.title}
        </Typography>
      </Box>
      <Divider sx={{ height: "3px", backgroundColor: "primary.main", mb: 4 }} />
      <ContentfulPostRenderer post={post} />
    </Container>
  );
};

export default KnowledgeBasePost;
