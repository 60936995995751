import React, { forwardRef } from "react";
import { NumericFormat, OnValueChange } from "react-number-format";
import { Box, InputAdornment, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { getDecimalPlaces, shouldFixDecimalScale } from "../../common/currencyDecimalFormater";

interface SelectOption {
  value: string;
  icon: string;
  label: string;
}

interface FormattedCurrencyFieldProps {
  name: string;
  value: string | number;
  placeholder?: string;
  onValueChange: OnValueChange;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  currency: string;
  selectOptions: SelectOption[];
  selectedOption: string;
  onSelectChange: (event: SelectChangeEvent, child?: React.ReactNode) => void;
  selectDisabled?: boolean;
  selectStyle?: React.CSSProperties;
  textFieldStyle?: React.CSSProperties;
  userHasGetQuoteRole: boolean;
  defaultOption: SelectOption;
}

const FormattedCurrencyField = forwardRef<HTMLInputElement, FormattedCurrencyFieldProps>(
  (
    {
      name,
      value,
      placeholder,
      onValueChange,
      onBlur,
      onKeyDown,
      onFocus,
      disabled = false,
      currency,
      selectOptions,
      selectedOption,
      onSelectChange,
      selectDisabled = false,
      selectStyle,
      textFieldStyle,
      userHasGetQuoteRole,
      defaultOption
    },
    ref
  ) => (
    <NumericFormat
      fullWidth
      variant="outlined"
      name={name}
      value={value}
      placeholder={placeholder}
      onValueChange={onValueChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      thousandSeparator
      decimalScale={getDecimalPlaces(currency)}
      fixedDecimalScale={shouldFixDecimalScale(currency)}
      customInput={TextField}
      inputRef={ref}
      sx={{
        ...textFieldStyle,
        "& .MuiInputBase-input::placeholder": {
          fontSize: "1.2rem",
          opacity: 0.8
        }
      }}
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Select
              name={name}
              value={selectedOption}
              onChange={onSelectChange}
              displayEmpty
              sx={selectStyle}
              disabled={selectDisabled}
            >
              {userHasGetQuoteRole ? (
                selectOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Box display="flex" alignItems="center">
                      <img src={option.icon} alt={option.label} style={{ marginRight: "10px", height: "30px" }} />
                      {option.value}
                    </Box>
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={defaultOption.value}>
                  <Box display="flex" alignItems="center">
                    <img src={defaultOption.icon} alt={defaultOption.label}
                         style={{ marginRight: "10px", height: "30px" }} />
                    {defaultOption.label}
                  </Box>
                </MenuItem>
              )}
            </Select>
          </InputAdornment>
        )
      }}
    />
  )
);

export default FormattedCurrencyField;
