// ============================|| CURRENCY CONVERTER STYLES ||============================ //
export const selectCurrencyStyle = {
  border: "none",
  backgroundColor: "transparent",
  fontSize: "1.2rem",
  "& .MuiSelect-select": {
    backgroundColor: "transparent",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSelect-icon": {
    color: "rgba(0, 0, 0, 0.54)",
  },
};

export const textFieldStyle = {
  mb: 2,
  "& .MuiInputBase-input": {
    fontSize: "1.2rem",
    padding: "20px 18px",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderWidth: "3px",
  },
};
