import { useNavigate } from "react-router-dom";
import React, { useContext } from "react";
// project imports
import { RolesContext } from "../store/context/RolesContext";
import GetEntities from "../requests/entities/GetEntities";
import HelpDrawer from "../ui-component/HelpDrawer";

/// ============================|| View Beneficiaries  ||============================ //
const ViewBeneficiaries = () => {
  const { roles } = useContext(RolesContext);
  const navigate = useNavigate();

  if (!roles.includes("beneficiaries::view")) {
    navigate("/");
  }
  return (
    <>
      <GetEntities />
      <HelpDrawer slug="view-beneficiary" />
    </>
  );
};
export default ViewBeneficiaries;
