import React from 'react';
import { Typography, Box, Divider } from "@mui/material";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

const ContentfulPostRenderer = ({ post }) => {
  if (!post) return <Typography>Loading...</Typography>;

  const renderOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography paragraph>{children}</Typography>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { file, title } = node.data.target.fields;
        return (
          <Box sx={{ marginBottom: 2 }}>
            <img src={file.url} alt={title} style={{ width: '100%' }} />
          </Box>
        );
      },
      [INLINES.HYPERLINK]: (node) => {
        const { uri } = node.data;
        return (
          <a href={uri} target="_blank" rel="noopener noreferrer">
            {node.content[0].value}
          </a>
        );
      },
    },
    renderText: (text) => text.split('\n').flatMap((part, i) => [i > 0 && <br key={i} />, part]),
  };

  return (
    <>
      {post.fields.image && (
        <Box sx={{ marginBottom: 2 }}>
          <img src={post.fields.image.fields.file.url} alt={post.fields.title} style={{ width: '100%' }} />
        </Box>
      )}
      <Typography variant="body1">
        {documentToReactComponents(post.fields.body, renderOptions)}
      </Typography>
    </>
  );
};

export default ContentfulPostRenderer;
