import React, { useContext, useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import { Edit, Save } from "@mui/icons-material";
import ConfigContext from "../store/context/ConfigContext";
import MainCard from "../ui-component/cards/MainCard";
import SubCard from "ui-component/cards/SubCard";
import { useAuthHeaders } from "../auth/AuthHeaders";
import CustomFormField from "../ui-component/form-fields/CustomFormField";
import { availableRoles, roleMapping } from "../auth/availableRoles";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required")
});

export function AccountSettings() {
  const authHeaders = useAuthHeaders();
  const config = useContext(ConfigContext);
  const [userdata, setUserData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const clientReference = sessionStorage.getItem("clientId");
  const tosAccepted = sessionStorage.getItem("tosAccepted");
  const tosUrl = sessionStorage.getItem("tosUrl");
  const shouldRefreshData = useRef(false);
  const [tosContent, setTosContent] = useState(null);
  const [tosDialogOpen, setTosDialogOpen] = useState(false);

  const getUserData = async () => {
    try {
      const response = await axios.post(
        `${config.API_URL}/login/v1/get_user`,
        {
          clientReference: clientReference,
          domainReference: `${config.LOGIN_DOMAIN_UUID}`,
          args: {}
        },
        { headers: authHeaders }
      );
      setUserData(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        sessionStorage.clear();
        window.location.href = "/token-expiry";
      } else {
        console.error("Error fetching user settings:", error);
      }
    }
  };

  // Fetch ToS content when the dialog is opened
  const fetchTosContent = async () => {
    try {
      const response = await axios.get(tosUrl, { headers: authHeaders });
      setTosContent(response.data);
    } catch (error) {
      console.error("Error fetching Terms of Service content:", error);
    }
  };

  const handleTosDialogOpen = () => {
    setTosDialogOpen(true);
    if (!tosContent) {
      fetchTosContent();
    }
  };

  const handleTosDialogClose = () => setTosDialogOpen(false);

  useEffect(() => {
    if (shouldRefreshData.current) {
      getUserData().then(() => {
        shouldRefreshData.current = false;
      });
    }
  }, [shouldRefreshData.current]);

  useEffect(() => {
    getUserData();
  }, []);

  const initialFormValues = {
    name: userdata?.user?.name || ""
  };

  const handleUserNameUpdateSubmit = async (values) => {
    try {
      await axios.post(
        `${config.API_URL}/login/v1/set_user`,
        {
          ...values
        },
        { headers: authHeaders }
      );
      setEditMode(false);
      shouldRefreshData.current = true;
    } catch (error) {
      console.error("There was an error updating the name!", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const shouldHideRolesTab = (realms) =>
    realms.some(
      (realm) =>
        realm.id === clientReference &&
        realm.roles.some((role) => role.name === "bank::payment")
    );

  const hideRolesTab = shouldHideRolesTab(userdata?.user?.realms || []);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={handleUserNameUpdateSubmit}
      >
        {({
            errors,
            touched,
            values,
            handleChange,
            handleBlur,
            submitForm
          }) => (
          <MainCard title="Account Settings">
            <Box sx={{ width: "100%", mt: -2, mb: 1 }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="simple tabs"
              >
                <Tab label="User Profile" />
                {!hideRolesTab && <Tab label="Roles & Permissions" />}
              </Tabs>
            </Box>
            {tabValue === 0 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SubCard>
                    <List>
                      <Typography variant="h4" sx={{ ml: 2 }}>
                        Account Info
                      </Typography>
                      {editMode ? (
                        <Form>
                          <Grid container spacing={2}>
                            <Grid item xs={8} md={3}>
                              <CustomFormField
                                name="name"
                                label="User name"
                                value={values.name}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                onClick={submitForm}
                                aria-label="save"
                                color="primary"
                              >
                                <Save />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Form>
                      ) : (
                        <>
                          <Grid container spacing={2}>
                            <Grid item xs={3} sx={{ ml: 2 }}>
                              <ListItemText
                                primary="User Name"
                                secondary={userdata?.user?.name}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                onClick={() => setEditMode(true)}
                                aria-label="edit"
                                color="primary"
                              >
                                <Edit />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </>
                      )}

                      {tosAccepted && tosUrl && (
                        <>
                          <Divider sx={{ m: 2 }} />
                          <Typography variant="h4" sx={{ ml: 2, mt: 2 }}>
                            Terms of Service
                          </Typography>
                          <Grid sx={{ ml: 2, mt: 2 }}>
                            <Typography variant="h5" gutterBottom sx={{ mb: 1 }}>
                              Accepted on:{" "}
                              {new Date(tosAccepted).toLocaleDateString()}
                            </Typography>
                            <Link onClick={handleTosDialogOpen}>
                              View Accepted Terms of Service
                            </Link>
                          </Grid>
                        </>
                      )}

                    </List>
                  </SubCard>
                </Grid>
              </Grid>
            )}
            {tabValue === 1 && !hideRolesTab && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SubCard>
                    <List>
                      {userdata?.user?.realms?.map((realm, index) => (
                        <React.Fragment key={index}>
                          <ListItem alignItems="flex-start">
                            <ListItemText
                              primary={"Client Account: " + realm.name}
                            />
                          </ListItem>
                          <Divider sx={{ mx: 2 }} />
                          <Grid container spacing={1}>
                            {availableRoles.map((role) => {
                              const isChecked = realm?.roles?.some(
                                (r) =>
                                  r.name === roleMapping[role.value] ||
                                  r.name === role.value
                              );
                              return (
                                isChecked && (
                                  <Grid item xs={6} sm={4} md={3} key={role.value}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          defaultChecked
                                          style={{ color: "green" }}
                                          disabled
                                        />
                                      }
                                      label={role.label}
                                    />
                                  </Grid>
                                )
                              );
                            })}
                          </Grid>
                        </React.Fragment>
                      ))}
                    </List>
                  </SubCard>
                </Grid>
              </Grid>
            )}
            <Dialog
              open={tosDialogOpen}
              onClose={handleTosDialogClose}
              maxWidth="xl"
              fullWidth
            >
              <DialogTitle>Terms of Service</DialogTitle>
              <DialogContent>
                <div
                  dangerouslySetInnerHTML={{
                    __html: tosContent || "<p>Loading...</p>"
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleTosDialogClose} color="secondary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </MainCard>
        )}
      </Formik>
    </>
  );
}
